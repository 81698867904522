import React from "react";
import logo from "../../img/logo.png";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "./BaseUrl";
import axios from "axios";

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: this.props.urlPath,
      balance: 0,
      virtualFund: 0,
      Rebalance: 0,
      nickname: "Guest",
      Firstname: "",
      email: "",
      checked: false,
      pair:""
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getOpenPair()
    this.location = this.props

  }
 
  getOpenPair=()=>{
    axios.get(baseUrl+"/api/get-open-pair").then((res)=>{
      
        if(res.data.status){
          this.setState({pair:`${res.data.data[0].first_name}/${res.data.data[0].second_name}`})

        }
      
      }).catch((err)=>{
  
      })
}
  componentWillMount() {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }

    var theme = localStorage.getItem("theme");
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      this.handleChange(theme);
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("token");
    if (token) {
      this.getUser();
    }
  }

  getUser = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      if (resp.data[0].nickname == "null" || resp.data[0].nickname == null) {
        this.setState({
          nickname: "Guest",
          email: resp.data[0].email,
          AccHolder: resp.data[0].AccHolder,
        });
      } else {
        this.setState({
          nickname: resp.data[0].nickname,
          email: resp.data[0].email,
          AccHolder: resp.data[0].AccHolder,
        });
      }
    });
  };

  logout = () => {
    localStorage.clear();
    this.props.history.push("/login-to-buy-sell-crypto");
    // window.location.href = "/login";
  };

  handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");

    if (checked === true) {
      tags[0].classList.add("dark-theme");
      this.setState({ checked: false });
    } else {
      tags[0].classList.remove("dark-theme");
      this.setState({ checked: true });
    }
    localStorage.setItem("theme", checked);
  };

  showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null && window.location.pathname == "/forgot") {
      return (
        <ul className="">
          <li className="">
            <Link className="btn btn_man" to="/login-to-buy-sell-crypto">Log in<i className="fa fa-chevron-right"></i></Link>
          </li>
          <li className="">
            <Link className="btn btn_man" to="/register-for-bitcoin-exchange">Sign up<i className="fa fa-chevron-right"></i></Link>
          </li>
        </ul>
      );
    }
    if (token === null && window.location.pathname !== "/forgot") {
      return (
        <ul className="">
          {/* <li className="">
            <Link to="/login-to-buy-sell-crypto">Log in</Link>
          </li>
          <li className="">
            <Link to="/register-for-bitcoin-exchange">Sign up</Link>
          </li> */}
        </ul>
      );
    }else {
      return (
        <ul className="ment_right ml-auto ">
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle d-flex align-items-center"
                data-toggle="dropdown"
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
               <span className="name_max_w"> {this.state.AccHolder ? this.state.AccHolder : this.state.email}</span>
              </span>
              <div className="dropdown-menu2">
              <Link className="a_flex" to="/profile">
                  <i className="fa fa-user mr-1"></i> Profile
                </Link>
                {/* <Link className="a_flex" to="/Transactions">
                  <i className="las la-wallet"></i> Transactions
                </Link> */}
                <Link className="a_flex" to="/Kyc">
                  <i className="fa fa-id-card-o"></i> My KYC
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="fa fa-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={this.logout}>
                  <i className="fa  fa-sign-out"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };
  logout = () => {
    var theme = localStorage.getItem("theme");
    localStorage.clear();
    localStorage.setItem("theme", theme);
    sessionStorage.clear();
    window.location.reload();
  };
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  render() {
    var token2 = localStorage.getItem("token");
    return (
      <>
        <nav className="navbar navbar-expand-lg in_header">
          <div className="container">
            <Link className="navbar-brand navbar-brand2" to="/">
              <img src="/assets/img/logo.png" alt="Logo" className="logo logo1" />
              <img src="/assets/img/logo-dark.png" alt="Logo" className="logo logo2" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              <span className="icon-bar">
                <i className="fa fa-bars fa-2x"></i>
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav  line align-items-center">
                <li className="nav-item">
                  {/* {!token2 ? (
                    <a className="nav-link active" href="/exchange/TRX/USDT">
                      Exchange
                    </a>
                  ) : (
                    <a className="nav-link active" href="/exchange/TRX/USDT">
                      Exchange
                    </a>
                  )} */}
                   {!token2 ? (
                  <Link className={`nav-link ${this.location.location.pathname=="/exchange/"+this.state.pair?"active":""}`} to={`/exchange/${this.state.pair}`}>
                    Exchange
                  </Link>
                ) : (
                  <Link className={`nav-link ${this.location.location.pathname=="/exchange/"+this.state.pair?"active":""}`} to={`/exchange/${this.state.pair}`}>
                    Exchange
                  </Link>
                )}
                </li>
                
                <li className="nav-item">
                  {this.state.checked == false ? (
                    <img
                      src="/assets/img/sun.png"
                      width="25px"
                      alt="sun"
                      className="sun-img theme-img"
                      onClick={() => this.handleChange(this.state.checked)}
                      checked={this.state.checked}
                    />
                  ) : (
                    <img
                      src="/assets/img/moon.png"
                      width="25px"
                      alt="moon"
                      className="moon-img theme-img"
                      onClick={() => this.handleChange(this.state.checked)}
                      checked={this.state.checked}
                    />
                  )}
                </li>
              </ul>
            </div>

            <div className="dashbord_menu">
            {this.showHeaderPart()}
          </div>
          </div>
        </nav>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
          >
            <img src="assets/img/v_kyc.png" alt="" />
            <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
              Verified Your KYC
            </h2>
            <button onClick={this.closeModal}>close</button>
            <div>I am a modal</div>
          </Modal>
        </div>
      </>
    );
  }
}

export default withRouter(MainHeader);
