import axios from "axios";
import React from "react";
import DocumentMeta from "react-document-meta";
import { Link } from "react-router-dom";
import { baseUrl } from "../../Common/BaseUrl";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePair: "/BTC/USDT",
    };
  }

  componentDidMount() {
    this.getOpenPair();
  }

  getOpenPair = () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            activePair: `${res.data.data[0].first_name}/${res.data.data[0].second_name}`,
          });
        }
      })
      .catch((err) => {});
  };
  render() {
    const meta = {
      title: "Buy Bitcoin, Trading Platform | ITOEX  Exchange",
      description:
        "ITOEX  Exchange is Most Secure Crypto Exchange Platform. Buy And Sell Crypto With Multi Currency Crypto Exchange With ITOEX  Exchange's Official Website.",
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "ITOEX  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India",
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
        <header className="home" id="home">
        
          <div className="container">
            <div className="home-outer position-relative">
            <img  src="assets/img/brain-smart-image.png" class="attachment1" alt=""/>
            <img  src="assets/img/polygon.png" class="attachment2" alt="polygon"/>
              <div className="row align-items-center">
              
                <div className="col-md-7 col-12  ">
                  <div className="banner-left">
                    <h1>
                      ITOEX Crypto<br /> Exchange  Beast   in<br />  Crypto
                      Trading!
                    </h1>
                    <p>
                      We have over 15 year exprience in business consultting
                      arena and artficial intelligence.
                    </p>
                    <div className="banner-btn-outer">
                      <Link
                        to={`/exchange/${this.state.activePair}`}
                        className="btn btn_man"
                      >
                        Trade Now <i className="fa fa-chevron-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-12  m-auto">
                  <div className="banner-right mt-5 position-relative">
                    <img
                      src="assets/img/robote-shape.png"
                      alt=""
                      className="img-fluid rotateme"
                    />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </DocumentMeta>
    );
  }
}

export default Header;
