import React from "react";

const NeedHelp = () => {
  return (
    <div className="help-main-outer pb-0">
      <div className="container">
        <div className="help-outer">
          <div className="row align-items-center">
           
            <div className="col-md-6 col-12 mt-4 mt-md-0 order-md-2">
              <div className="section-heading ">
                <h2>Why Choose ITOEX!</h2>
                <p>
                ITOEX Exchange is India's one of the most crypto exchange
                  platforms in India where you can buy or sell Bitcoin,
                  Ethereum, Tron, Ripple and various other cryptocurrencies. Our
                  crypto trading platform never leaves a single stone unturned
                  to make it the most secure crypto exchange where you can get
                  an experience of hassle-free trading with an efficient crypto
                  wallet. ITOEX Exchange can handle thousands of transactions
                  within a few seconds during surging demand.
                </p>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="help-inner d-flex flex-wrap  position-relative">
                    <div className="img-outer">
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="content-outer">
                      <b>Safty Comes First</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="help-inner d-flex flex-wrap  position-relative">
                    <div className="img-outer">
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="content-outer">
                      <b>Fast Transactions</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="help-inner d-flex flex-wrap  position-relative">
                    <div className="img-outer">
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="content-outer">
                      <b>Bonus & Refferal</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="help-inner d-flex flex-wrap  position-relative">
                    <div className="img-outer">
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="content-outer">
                      <b>Easy Deposit & Withdrawals</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="help-inner d-flex flex-wrap  position-relative">
                    <div className="img-outer">
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="content-outer">
                      <b>Low Charges</b>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="help-inner d-flex flex-wrap  position-relative">
                    <div className="img-outer">
                      <i className="fa fa-check"></i>
                    </div>
                    <div className="content-outer">
                      <b>24/7 Support</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 text-center order-md-1">
              <img src="/assets/img/coose.png" alt="" className="img-fluid z1     position-relative" />
              <img src="assets/img/polygon.png" class="attachment3" alt="polygon"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedHelp;
