// const mainUrl = "http://192.168.1.41:8000/frontapi",
const mainUrl = "https://api.itoex.io/frontapi",
  base = {
    whitelistData: "/whitelistData",
    deleteWhitelist: "/deleteWhiteAddress",
    whitelistAdd: "/whitelist",
    cryptoCoinList: "/cryptocoin_list",
    userData: "/UserData",
    deleteWhiteList: "/deleteWhiteAddress",
    coinPairList: "/coin_pair_list",
  };

export { base, mainUrl };
