import React, { useEffect, useState } from "react";
import { Component } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../img/logo.png";
import * as myConstList from "./BaseUrl";
import axios from "axios";
// import jwt from "jsonwebtoken";
import config from "./jwt_config";
import { toast } from "react-toastify";
const baseUrl = myConstList.baseUrl;

export default function LoginHeader(props) {
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setchecked] = useState(false);
  const [nickname, setNickname] = useState("Guest");
  const [Firstname, setFirstname] = useState("");
  const [LastName, setLastName] = useState("");
  const [accHolder, setAccHolder] = useState("");
  const [mobile_no, setMobile_no] = useState("");
  const [pair, setPair] = useState("");
  const history = useHistory();
  useEffect(() => {
    var token1 = localStorage.getItem("token");
    if (token1 === null) {
      return history.push("/login-to-buy-sell-crypto");
    }
  });

  useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", true);
    }
    var theme = localStorage.getItem("theme");
    if (theme == "true" || theme == "false") {
      theme = JSON.parse(theme);
      handleChange(theme);
    }
  }, []);

  useEffect(() => {
    getData();
    getOpenPair();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/frontapi/UserData", {}, config).then((resp) => {
      var resp = resp.data;
      if (
        resp.key === "logout" &&
        localStorage.getItem("token") !== "null" &&
        localStorage.getItem("token") !== null
      ) {
        // toast.error(resp.message);
        localStorage.clear();
        setTimeout(
          () => (window.location.href = "/login-to-buy-sell-crypto"),
          2000
        );
      }
      if (resp.data[0].nickname == null) {
        setNickname("Guest");
        setEmail(resp.data[0].email);
        setFirstname(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        // setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);
      } else {
        setNickname(resp.data[0].nickname);
        setEmail(resp.data[0].email);
        setFirstname(resp.data[0].firstName);
        setLastName(resp.data[0].lastName);
        // setAccHolder(resp.data[0].AccHolder);
        setMobile_no(resp.data[0].mobile_no);
      }
    });
  };
  const showHeaderPart = () => {
    var token = localStorage.getItem("token");
    if (token === null) {
      history.push("/login-to-buy-sell-crypto");
    } else {
      const maxCharacters = 19;
      const truncatedEmail =
        email && email.length > maxCharacters
          ? email.slice(0, maxCharacters) + "..."
          : email;
      return (
        <ul>
          <li className="">
            <div className="dropdown user_dropdown">
              <span
                type="button"
                className=" dropdown-toggle d-flex align-items-center"
                data-toggle="dropdown"
                title={email ? email : mobile_no}
              >
                <i className="fa fa-user-circle mr-1"></i>{" "}
                <span className="name_max_w"> {email ? truncatedEmail : mobile_no}</span>
              </span>
              <div className="dropdown-menu2">

                <Link className="a_flex" to="/profile">
                  <i className="fa fa-user  "></i> Profile
                </Link>
                {/* <Link className="a_flex" to="/Transactions">
                  <i className="las la-wallet"></i> Transactions
                </Link> */}
                <Link className="a_flex" to="/Kyc">
                  <i className="fa fa-id-card-o"></i> My KYC
                </Link>
                <Link className="a_flex" to="/help">
                  <i className="fa fa-headphones"></i> Support
                </Link>
                <Link to="#" className="a_flex" onClick={logout}>
                  <i className="fa fa-sign-out"></i>Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };

  const logout = () => {
    toast.success("Logout Successfully");
    let interval = setInterval(() => {
      var theme = localStorage.getItem("theme");
      localStorage.clear();
      localStorage.setItem("theme", theme);
      history.push("/login-to-buy-sell-crypto");
      clearInterval(interval);
    }, 1000);
  };
  const handleChange = (checked) => {
    let tags = document.getElementsByTagName("body");

    if (checked === true) {
      tags[0].classList.add("dark-theme");
      setchecked(false);
    } else {
      tags[0].classList.remove("dark-theme");
      setchecked(true);
    }
    localStorage.setItem("theme", checked);
  };
  var token2 = localStorage.getItem("token");
  const getOpenPair = () => {
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          setPair(
            `${res.data.data[0].first_name}/${res.data.data[0].second_name}`
          );
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="responsive_in ">
      <nav className="navbar navbar-expand-lg nav-scroll2">
        <div className="container-fluid ">
          <Link className="navbar-brand" to="/index">
          <img
              src="/assets/img/logo.png"
              alt="header-Logo"
              className="logo logo1"
            />
             <img
              src="/assets/img/logo-dark.png"
              alt="header-Logo"
              className="logo logo2"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
          >
            <span className="icon-bar">
              <i className="fa fa-bars fa-2x"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav  line align-items-center">
              <li className="nav-item">
                {/* {!token2 ? (
                  <a className="nav-link " href="/exchange/TRX/USDT">
                    Exchange
                  </a>
                ) : (
                  <a className="nav-link " href="/exchange/TRX/USDT">
                    Exchange
                  </a>
                )} */}
                {!token2 ? (
                  <a className="nav-link " href={`/exchange/${pair}`}>
                    Exchange
                  </a>
                ) : (
                  <a className="nav-link " href={`/exchange/${pair}`}>
                    Exchange
                  </a>
                )}
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname == "/account" ? "active" : ""
                  }`}
                  to="/account"
                >
                  Account
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/transactions" ||
                    location.pathname === "/depositeHistory" ||
                    location.pathname === "/coinDeposite" ||
                    location.pathname === "/coinFundRequest"
                      ? "active"
                      : ""
                  }`}
                  to="/transactions"
                >
                  Transactions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname == "/spot" ? "active" : ""
                  }`}
                  to="/spot"
                >
                  Trade
                </Link>
              </li>

              <li className="nav-item">
                {checked == false ? (
                  <img
                    src="/assets/img/sun.png"
                    alt="sun"
                    width="25px"
                    className="sun-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                ) : (
                  <img
                    src="/assets/img/moon.png"
                    alt="moon"
                    width="25px"
                    className="moon-img theme-img"
                    onClick={() => handleChange(checked)}
                    checked={checked}
                  />
                )}
              </li>
            </ul>
          </div>
          <div className="dashbord_menu">
            <ul className="">{showHeaderPart()}</ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
