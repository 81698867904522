import React from "react"
import {Component} from "react"

export class NotFound extends Component{
    render(){
        return(<div>
          <div className="not_found">
			  <div>
			  <div className="fourx4"> <img src="/assets/img/not_found.png" className="img-fluid"/> </div>
				  <h1 className="">OOPS! PAGE NOT FOUND</h1>
				 <a className="btn btn_man mt-4  w100px mt-4" href="/">Go Home</a>
			  </div>
		  </div>
        </div>)
    }
}

export default NotFound;