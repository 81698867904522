import React from "react";
import icon1 from "../../../img/icon1.png";
import icon1_h from "../../../img/icon1-h.png";
import icon2 from "../../../img/icon2.png";
import icon2_h from "../../../img/icon2-h.png";
import icon3 from "../../../img/icon3.png";
import icon3_h from "../../../img/icon3-h.png";

class Testimonial extends React.Component {
  render() {
    return (
      <div className="how-main-outer p60 text-center">
        <div className="container">
           <div className="section-heading mb-md-4">
				 <h2>Start Trading in Few Steps</h2>
				
			   </div>
          <div className="how-outer">
		   <div className="row align-items-center">
           
 
              <div className="col-md-3 col-12   mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/app03r34.png" alt="" className="img-fluid" />
                  </div>
                  <div className="content-outer">
                   
                    <h3>Create Account</h3>
                    <p>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/cryptomining-hit.png" alt="" className="img-fluid"  />
                  </div>
                  <div className="content-outer">
				 
                    <h3>Verify Your Account</h3>
                    <p>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 mb-4 ">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/crypto-wallet-hit.png" alt="" className="img-fluid"  />
                  </div>
                  <div className="content-outer">
				 
                    <h3>Add Fund in Wallet</h3>
                    <p>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
                  </div>
                </div>
              </div>
			  <div className="col-md-3 col-12 mb-4">
                <div className="how-inner ">
                  <div className="img-outer">
                  <img src="assets/img/crypto-trading-hit.png" alt=""  className="img-fluid" />
                  </div>
                  <div className="content-outer">
				  
                    <h3>Start Trading Instantiy</h3>
                    <p>Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.</p>
                  </div>
                </div>
              </div>
            </div>
           </div>
          </div>
          </div>
    );
  }
}

export default Testimonial;
