import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import * as myConsList from "../../Common/BaseUrl";

const baseUrl = myConsList.baseUrl;

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };

    this.GetCoinData();
  }

  GetCoinData = () => {
    axios.get(baseUrl + "/frontapi/Coin_Pair_Data", {}).then((resp) => {
      var resp = resp.data;

      this.setState({ CoinTableData: resp.data, coinTableStatus: resp.status });
    });
  };

  AddressListHtml = () => {
    if (this.state.coinTableStatus === true) {
      const html = [];

      this.state.CoinTableData.map(function (value, i) {
        if (value.coin_Second_name === "USDT") {
          html.push(
            <tr>
              <td>
                {
                  <img
                    src={baseUrl + "/static/currencyImage/" + value.coinIcon}
                  />
                }
              </td>
              <td>
                {value.coin_first_name} / {value.coin_Second_name}
              </td>

              <td>
                {parseFloat(
                  value.currentPrice ? value.currentPrice : 0
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  value.changePriceAmount ? value.changePriceAmount : 0
                ).toFixed(2)}
              </td>
              <td>
                {parseFloat(
                  value.oneDayMaxPrice
                    ? value.oneDayMaxPrice
                    : parseFloat(
                        value.currentPrice ? value.currentPrice : 0
                      ).toFixed(2)
                )}
              </td>
              <td>
                {parseFloat(
                  value.oneDayMinPrice
                    ? value.oneDayMinPrice
                    : parseFloat(
                        value.currentPrice ? value.currentPrice : 0
                      ).toFixed(2)
                )}
              </td>
              <td>
                {parseFloat(
                  value.oneDayVolume ? value.oneDayVolume : 0
                ).toFixed(2)}
              </td>
            </tr>
          );
        }
      });

      return <tbody className="main">{html}</tbody>;
    }
  };
  changeClass = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  render() {
    return (
      <>
  
      <div className="p60 intro-main-outer" id="token" data-scroll-index="3">
        <div className="container">
      


          <div className="intro-outer">
            <div className="row align-items-center">
              <div className="col-md-5 col-12 ml-auto">
                <div className="intro-left">
                  <img
                    src="assets/img/about-01.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="ab22">
                   <img
                    src="assets/img/about-02.png"
                    alt=""
                    className="img-fluid"
                  /></div>
                </div>
                <img  src="assets/img/about-us-design-element.png" class="about-us-design" alt=""/>
              </div>
              <div className="col-md-6 col-12 mr-auto pl-md-5">
                <div className="intro-right">
                  <h2>Introducing<br/> ITOEX Exchange</h2>
                  <h6>
                    Just like Bitcoin and other cryptocurrencies today, ITOEX
                    is a token created by ITOEX. There will be a maximum of
                    1 Billion ITOEX coins ever created.
                  </h6>
                  <p>
                  ITOEX , a utility token backed by ITOEX EX, forms the
                    backbone of ITOEX EX ecosystem. We launched ITOEX tokens
                    to involve our community in helping us build out ITOEX EX,
                    and reward them accordingly for contributing to our success.
                    This helps us stay true to the ethos of cryptocurrency and
                    blockchain - to share the rewards of ITOEX EX's success with
                    our early adopters and supporters.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="white_box5">
            <h4 className=" ">ITOEX Market Price</h4>
            <div className="table-responsive">
              <div className="scrool_wallet_box">
                <table className=" table table-striped mb-0 ">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Pair</th>
                      {/* <th>Coin</th> */}
                      <th>Last Price ($)</th>
                      <th>24h Change ($)</th>
                      <th>24h High ($)</th>
                      <th>24h Low ($)</th>
                      <th>24h Volume ($)</th>
                    </tr>
                  </thead>
                  {this.AddressListHtml()}
                </table>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Table;
