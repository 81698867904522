import React from "react";
import { Component } from "react";
import MainHeader from "./Common/MainHeader";
import Footer from "./Common/Footer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "./Common/BaseUrl";
import { Link } from "react-router-dom";
import { ProductName } from "./Common/ProductName";
import DocumentMeta from "react-document-meta";

const baseUrl = myConstList.baseUrl;

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      mailStatus: "",
      nameError: "",
      emailError: "",
      subjectError: "",
      messageError: "",
    };
  }

  onBlurNameChange(event) {
    var na = event.target.value;
    if (!na) {
      toast.dismiss();
      toast.error("Name is require!");
    }
  }

  onBlurEmailChange(event) {
    var ea = event.target.value;
    if (!ea) {
      toast.dismiss();
      toast.error("Email is require!");
    }
  }

  onBlurSubjectChange(event) {
    var su = event.target.value;
    if (!su) {
      toast.dismiss();
      toast.error("Subject is require!");
    }
  }

  onNameChange(event) {
    this.setState({ name: event.target.value,nameError:"" });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value,emailError:"" });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value,subjectError:"" });
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value,messageError:"" });
  }

  submitEmail(e) {
    e.preventDefault();

    if (
      !this.state.name &&
      !this.state.email &&
      !this.state.subject &&
      !this.state.message
    ) {
      this.setState({
        nameError: "This filed is require",
        emailError: "This filed is require",
        subjectError: "This filed is require",
        messageError: "This filed is require",
      });
      // toast.error("Please fill all the fileds");
      return false;
    }

    var details = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
    };
    axios.post(baseUrl + "/frontapi/sendContactMail", details).then((resp) => {
      var resp = resp.data;
      if (resp.status === true) {
        this.resetForm();
        toast.success("Mail sent successfully");
      }
    });
  }

  resetForm() {
    this.setState({ name: "", email: "", subject: "", message: "" });
  }

  render() {
    const meta = {
      title: `Contact Us | ${ProductName}  Exchange `,
      description:
        "Get A User Friendly Crypto Exchange App, A Local Crypto Exchange Platform In India For Bitcoin Exchange And Other Crypto Currencies Trading. Sign Up Now.",
      canonical: `https://${ProductName}.live/contact-us-for-crypto-exchange`,
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "Crypto Exchange, Local Crypto Exchange, BTC Exchange, Bitcoin Exchange",
        },
      },
    };
    return (
      <div>
        <DocumentMeta {...meta}>
          <div>
            <MainHeader />

            <div className="privacy_policy">
              <div className="container container2">
                <div className="wrapper  white_box">
                  <div className="row ">
                    <div className="col-md-7 d-flex align-items-stretch">
                      <div className="contact-wrap w-100 ">
                        <h3 className="mb-4">Get in touch</h3>
                        <div id="form-message-warning" className="mb-4" />
                        <form
                          onSubmit={this.submitEmail.bind(this)}
                          method="post"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  id="name"
                                  placeholder="Name"
                                  value={this.state.name}
                                  onChange={this.onNameChange.bind(this)}
                                  onBlur={this.onBlurNameChange}
                                />
                                {this.state.nameError && (
                                  <span style={{ color: "red" }}>
                                    {this.state.nameError}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  id="email"
                                  placeholder="Email"
                                  value={this.state.email}
                                  onChange={this.onEmailChange.bind(this)}
                                  onBlur={this.onBlurEmailChange}
                                />
                                {this.state.emailError && (
                                  <span style={{ color: "red" }}>
                                    {this.state.emailError}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subject"
                                  id="subject"
                                  placeholder="Subject"
                                  value={this.state.subject}
                                  onChange={this.onSubjectChange.bind(this)}
                                  onBlur={this.onBlurSubjectChange}
                                />
                                {this.state.subjectError && (
                                  <span style={{ color: "red" }}>
                                    {this.state.subjectError}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea
                                  name="message"
                                  className="form-control"
                                  id="message"
                                  cols={30}
                                  rows={7}
                                  placeholder="Message"
                                  defaultValue={""}
                                  value={this.state.message}
                                  onChange={this.onMsgChange.bind(this)}
                                />
                                {this.state.messageError && (
                                  <span style={{ color: "red" }}>
                                    {this.state.messageError}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <input
                                  type="submit"
                                  className="btn btn_man "
                                  defaultValue="Send Message"
                                />
                                <div className="submitting" />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-5 d-flex align-items-stretch">
                      <div className="info-wrap bg_contact w-100 pl-lg-5 ">
                        <h3 className="mb-4 mt-md-4">Contact us</h3>
                        <div className="dbox w-100 d-flex align-items-start mb-2">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="fa fa-map-marker" />
                          </div>
                          <div className="text pl-3 ">
                            <p className="mt-0">
                              <span>Address: </span>
                              Mahendergarh,
                              <br />
                              Haryana PIN:- 123027
                            </p>
                          </div>
                        </div>
                        <div className="dbox w-100 d-flex align-items-center mb-3">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="fa fa-phone" />
                          </div>
                          <div className="text pl-3">
                            <p>
                              <span>Phone:</span>{" "}
                              <h6 to="tel:#">+91 9876543210 </h6>
                            </p>
                          </div>
                        </div>
                        <div className="dbox w-100 d-flex align-items-center">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="fa fa-paper-plane" />
                          </div>
                          <div className="text pl-3">
                            <p>
                              <span>Email:</span>{" "}
                              <h6 to="mailto:support@axf.com">
                                support@dex.com
                              </h6>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer autoClose={2000} limit={1} />
        </DocumentMeta>
      </div>
    );
  }
}

export default ContactUs;
